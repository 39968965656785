import React, {useState, useEffect, useContext} from 'react';
import {Link} from 'react-router-dom';
import axios from 'axios';
import { useParams } from "react-router";
import Comments from './comments.component';
import { GlobalContext } from "../GlobalContext";
import Navbar from './navbar.component'


const ListLink = (props) => {
    switch(props.faction){        
        case 2:
            return <Link to={`/listbuilder/empire/${props.id}`}>{props.name}</Link>
        case 1:
            return <Link to={`/listbuilder/rebel/${props.id}`}>{props.name}</Link>
        case 5:
            return <Link to={`/listbuilder/separatist%20alliance/${props.id}`}>{props.name}</Link>
        case 4:            
            return <Link to={`/listbuilder/galactic%20republic/${props.id}`}>{props.name}</Link>
        case 6:
            return <Link to={`/listbuilder/mercenary/${props.id}`}>{props.name}</Link>
    }
}

const PublicProfile = () => {   
   const [ lists, setLists ] = useState([])
   const [ imagePosts, setImagePosts ] = useState([])
   const [ user, setUser ] = useState({})
   const {username, userId} = useContext(GlobalContext)
   let {id} = useParams()
   useEffect(() => {
       axios.get('/api/user-lists/' + id)
       .then(r => {
           setLists(r.data)
       })
       axios.get('/api/user-image-posts/' + id)
       .then(r => setImagePosts(r.data))
       axios.get('/api/get-user/' + id)
       .then(r => setUser(r.data))
   }, [])
    return(
        <div>
            <Navbar /><br /><br />
            <div className="page-background">
           <div className="latest-lists-username header public-profile-username"><img src={user.avatar && user.avatar.replace('/upload/', '/upload/ar_1:1,c_fill,g_auto,r_max,w_50/')} />{user.username} </div>
           <div className="container">
           <div className="public-profile-wrapper">
                <div style={{overflow: 'auto', maxHeight: '90vh'}}>
                    <div className="header">Image Posts</div>
            {imagePosts.map(i => (
                <div key={i.id} className="image-post">                
                    <div className="image-post-title"><img src={i.avatar && i.avatar.replace('/upload/', '/upload/ar_1:1,c_fill,g_auto,r_max,w_50/')} />{i.username}</div> 
                    <div className="image-post-subtitle">{i.title && i.title}{!i.title && "Untitled"}</div>   
                    <img style={{maxWidth: '100%'}} src={i.image_url} />              
                    <Comments imagePost={i.id} userId={userId} username={username} />
                </div>
                ))}
            </div>
            <div style={{textAlign: 'center'}}>
                    <div className="header">Lists</div>
                    <br />
                {lists.map(l =>  <div><ListLink faction={l.faction_fkey} id={l.id} name={l.name}/></div>)}
            </div>
            </div>
        </div>
        </div>
        </div>
    )
}

export default PublicProfile;